import React, { Component } from 'react';

import { Modal, Button } from 'react-bootstrap';

class RcModal extends Component {
  render() {
    let showMessages = [];
    if (this.props.ModalData && this.props.ModalData.Messages) {
      let Messages = this.props.ModalData.Messages;
      let hasQuestion = false;
      let filteredMessages = [];
      for (let i = 0; !hasQuestion && i < Messages.length; i++) {
        let message = Messages[i];
        if (!hasQuestion && message.Type === 2 && message.Response === 0) {
          hasQuestion = true;
          filteredMessages.push(message);
        }
      }
      showMessages = hasQuestion ? filteredMessages : Messages;
    }

    let index = 1;

    return (
      <div>
        {this.props.ModalData !== null && (
          <Modal bsSize='large' show={this.props.modalData !== null} onHide={() => this.props.setModalData(null)}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h6>{this.props.ModalData.Title}</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: 'auto' }} className='col-lg-12'>
              {this.props.ModalData.SubTitle !== this.props.ModalData.Title && <h6>{this.props.ModalData.SubTitle} </h6>}
              {this.props.ModalData !== null && this.props.ModalData.Component}
              {this.props.ModalData !== null &&
                showMessages !== undefined &&
                showMessages.map((m) => (
                  <div key={index++}>
                    <p dangerouslySetInnerHTML={{ __html: '<div>' + m.Text.toString().replaceAll('\n', '<br/>') + '</div>' }} />

                    <p className='font-weight-light'>{m.AdditionalInfo}</p>
                    <p className='text-right font-weight-light'>
                      <i>{m.Title}</i>
                    </p>
                  </div>
                ))}
            </Modal.Body>
            {this.props.ModalData.hideFooter !== true && (
              <>
                {!this.props.ModalData.isQuestion && (
                  <Modal.Footer>
                    {this.props.ModalData.CloseButtonTitle !== null && (
                      <Button onClick={() => this.props.setModalData(null)}>
                        {this.props.ModalData.CloseButtonTitle != null ? this.props.ModalData.CloseButtonTitle : 'Close'}
                      </Button>
                    )}
                  </Modal.Footer>
                )}
                {this.props.ModalData.isQuestion && (
                  <Modal.Footer>
                    <button className='btn btn-default' onClick={() => this.props.setModalData(null)}>
                      Όχι
                    </button>
                    <button
                      className='btn btn-danger'
                      onClick={() => {
                        this.props.setModalData(null);
                        this.props.ModalData.handleConfirm(this.props.ModalData.Messages);
                      }}>
                      Ναι
                    </button>
                  </Modal.Footer>
                )}
              </>
            )}
          </Modal>
        )}
      </div>
    );
  }
}

export default RcModal;
