import React, { Component } from 'react';
import { Button, FormGroup, FormControl, Form } from 'react-bootstrap';
import axios from 'axios';

export default class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      loginData: null,
    };
  }

  componentDidMount = () => {
    const box = document.getElementById('loginBox');
    box.style.marginTop = window.outerHeight / 3.25 + 'px';

    const container = document.getElementById('container');
    container.style.height = window.innerHeight + 'px';
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  toUnicode = (theString) => {
    var unicodeString = '';
    for (var i = 0; i < theString.length; i++) {
      var theUnicode = theString.charCodeAt(i).toString(16).toUpperCase();
      while (theUnicode.length < 4) {
        theUnicode = '0' + theUnicode;
      }
      theUnicode = '\\u' + theUnicode;
      unicodeString += theUnicode;
    }
    return unicodeString;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    var loginData = this.toUnicode(this.state.email) + '|' + this.toUnicode(this.state.password);
    const url = this.props.appProps.rootUrl + '&Parameters=Query:Login,LoginData:' + loginData;
    console.log(this.props, url);

    axios.get(url).then((response) => {
      this.loginData = response.data;
      //console.log(response.data)
      this.setState({ loginData: response.data });

      if (response.data.Ενεργός_στο_Web) {
        this.props.appProps.setLoginData(response.data);

        if (this.props.windowProps) {
          console.log(this.props);
          this.props.closeWindow(this.props.windowProps.id);
          this.props.appProps.setModalData({
            Title: 'resCom',
            SubTitle: 'Καλωσήρθες',
            Messages: [{ Text: response.data.Ονοματεπώνυμο }],
          });
        }
      }
    });
  };

  render() {
    return (
      <div id='container' className='login-container'>
        <div className='login-bg-image' style={{ backgroundImage: "url('/bg.jpg')" }}></div>
        <div className='login-box' id='loginBox'>
          <div className='login-logo'>
            <b style={{ color: '#233c5d' }}>resCom</b>
            <span style={{ color: '#2458bb' }}>Articles</span>
          </div>
          <div className='login-box-body'>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup controlId='email' style={{ width: '100%', marginTop: '20px' }}>
                <FormControl className='login-input' placeholder='username' autoFocus type='test' value={this.state.email} onChange={this.handleChange} />
              </FormGroup>

              <FormGroup  controlId='password' style={{ width: '100%', marginTop: '25px' }}>
                <FormControl className='login-input' placeholder='password' value={this.state.password} onChange={this.handleChange} type='password' />
              </FormGroup>
              <FormGroup controlId='Buttons'>
                <Button block type='submit' style={{ width: '100%', marginTop: '25px' }}>
                  Σύνδεση
                </Button>
              </FormGroup>

              {this.state.loginData !== null && (
                <FormGroup controlId='Alerts'>
                  <div style={{ display: 'flex', width: '100%', marginTop: '25px', justifyContent: 'center', fontSize: '15px', color: 'darkred' }}>
                    <span>Πρόβλημα με τα στοιχεία εισόδου</span>
                  </div>
                </FormGroup>
              )}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
