import React, { useState, useEffect } from 'react';

import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import RcSelect from './RcSelect_v.2';
import Tag from './TagControl';

import { OrderListItem, OrderList } from './OrderList';
import PropagateLoader from 'react-spinners/PropagateLoader';
var counter = 1;

function Tags(props) {
  counter++;

  const [tag, setTag] = useState(null);
  const [tag_id, setTag_id] = useState(null);
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [readonly, setReadonly] = useState(true);

  const [tagsTypes, setTagsTypes] = useState(props.tagsType ?? null);
  const [tagsTypeFilter, setTagsTypeFilter] = useState(props.tagType ?? '');

  const DataUrl = props.appProps.rootUrl + '&Parameters=Query:tags_list';

  const [data, setData] = useState(null);
  const [filter, setFilter] = useState('');

  const [spinner, setSpinner] = useState(false);
  const [delayLoad, setDelayLoad] = useState();

  useEffect(() => {
    console.log('useEffect tag_id', tag_id);

    if (tag_id === null) {
      loadList(filter);
      setTag(null);
    } else if (tag_id === 0) {
      setData(null);
      setTag({ id: 0 });
    } else {
      setData(null);
      loadtag(null, tag_id);
    }
  }, [tag_id, filter, tagsTypeFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tagsTypes === null) {
      var url = props.appProps.rootUrl + `&Parameters=Query:tags_types`;
      axios(url).then((response) => setTagsTypes(response.data));
    }
  }, [tagsTypes]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadList = (f) => {
    if (f.length < 3 && (!tagsTypeFilter || props.modal)) {
      setData(undefined);
      setSelectedTagId(null);
      return;
    }

    clearTimeout(delayLoad);

    setDelayLoad(
      setTimeout(() => {
        console.log('loadList ', f);
        let url = DataUrl;
        if (f.trim(' ') !== '') url = url + ',filter:' + f;
        if (tagsTypeFilter && tagsTypeFilter !== '') url = url + ',tagstypefilter:' + tagsTypeFilter;
        setSpinner(true);
        axios(url).then((response) => {
          setSelectedTagId(null);
          setData(response.data);
          setSpinner(false);
        });
      }, 500)
    );
  };

  const loadtag = async (item, value) => {
    const url = props.appProps.rootUrl + `&Parameters=Query:get_tag,id:${value}`;
    var response = await axios.get(url);
    console.log(counter, 'setting Tag');
    setTag(response.data);
    console.log('loading tag end:', value); //////////////
  };

  const orderItem = async (value, isUp, article_id, from_tag_id) => {
    const url =
      props.appProps.rootUrl +
      `&Parameters=Query:orderItem,id:${value},isUp:${isUp}${article_id ? `,article_id:${article_id}` : ''}${from_tag_id ? `,from_tag_id:${from_tag_id}` : ''}`;
    var response = await axios.get(url);

    setTag(response.data);
  };

  if (props.match) {
    if (!props.match.params.id) {
      if (tag_id !== null) {
        console.log('del tag:', props.match.params.id);
        setTag_id(null);
      }
    } else if (props.match.params.id === '0') {
      if (tag_id === null || tag_id !== 0) {
        setTag_id(0);
        if (readonly) setReadonly(0);
      }
    } else if (props.match.params.id !== '0') {
      if (tag_id === null || tag_id.toString() !== props.match.params.id) {
        setTag_id(parseInt(props.match.params.id));
      }
    }
  }
  const savetag = (callback) => {
    var o = {
      Authentication: props.appProps.loginData.Auth,
      EndPoint: 'Lndnyk0joJElqcnvIt-ve-fRLByNjYg_TZlqYy7Mz2A.',
      Data: {
        Parameters: [
          {
            Name: 'object',
            Value: 'tag',
          },
          {
            Name: 'id',
            Value: tag.id,
          },
          {
            Name: 'name',
            Value: tag.name,
          },
          {
            Name: 'tag_data',
            Value: tag.data,
          },
          {
            Name: 'info',
            Value: tag.info,
          },
          {
            Name: 'type_id',
            Value: tag.type_id ? tag.type_id : 1,
          },
          {
            Name: 'tags',
            Value: tag.tags ? tag.tags.map((o) => o.id).join(',') : '',
          },
        ],
      },
    };

    axios.post(props.appProps.rootPostUrl, o).then((res) => {
      props.appProps.setAlertData({
        type: 'success',
        Title: 'resCom',
        SubTitle: 'Ενημέρωση',
        Messages: [{ Text: 'Η αποθήκευση ολοκληρώθηκε με επιτυχία!' }],
      });

      console.log('id: ', res.data.id);
      if (callback) callback(res.data.id);
    });
  };

  const deletetag = async (callback) => {
    axios(props.appProps.rootUrl + `&Parameters=Query:delete_tag,id:${tag.id}`).then((response) => {
      if (response.data && response.data.error) {
        props.appProps.setModalData({
          Title: 'resCom',
          SubTitle: 'Πρόβλημα',
          Messages: [{ Text: `Η διαγραφή ΔΕΝ ολοκληρώθηκε!<br/><br/>${response.data.error}` }],
        });
      } else {
        setData(null);
        setTag(null);

        props.appProps.setAlertData({
          type: 'success',
          Title: 'resCom',
          SubTitle: 'Ενημέρωση',
          Messages: [{ Text: 'Η διαγραφή ολοκληρώθηκε με επιτυχία!' }],
        });

        if (callback) callback();
      }
    });
  };

  const handleSelectChange = (a, b, c, d, e) => {
    console.log('handleSelectChange:', a, b, c);

    var tmpTag = { ...tag };
    tmpTag[b] = c;
    setTag(tmpTag);
  };

  return (
    <div className='non-home-container'>
      {!tag && (
        <div style={{ padding: '15px', flexGrow: '1', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <div style={{ flexShrink: '1' }}>
            {!props.modal && (
              <Button className='btn btn-primary btn-sm float-right' style={{ marginBottom: '20px' }} type='button' onClick={() => props.history.push(`/tags/0`)}>
                Νέα Ετικέτα
              </Button>
            )}
            <Form.Control
              style={{ marginBottom: '20px' }}
              placeholder='Φίλτρο'
              type='text'
              onChange={(event) => {
                setFilter(event.target.value);
              }}
              value={filter}
            />
            <RcSelect
              style={{ marginBottom: '20px' }}
              placeholder='με τύπο...'
              onChange={(a, b, c) => {
                setTagsTypeFilter(c);
              }}
              name='type_id'
              valueProperty='id'
              labelProperty='name'
              isClearable={true}
              value={tagsTypeFilter}
              isDisabled={props.tagType}
              options={tagsTypes}></RcSelect>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '13px', opacity: '0.3' }}>{spinner && <PropagateLoader size='10px' />}</div>
            {data && spinner === false && data.length === 0 && <p style={{ marginLeft: '3px', fontSize: '16px' }}>Δεν υπάρχουν εγγραφές </p>}
            {data === null && <p>Φόρτωση...</p>}
          </div>
          <div style={{ display: 'flex', flexGrow: '1', overflowY: 'hidden' }}>
            {data && data.length !== 0 && (
              <div style={{ flexGrow: '1', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                <div style={{ flexShrink: '1', textAlign: 'end', margin: '5px', fontSize: '13px' }}>{data.length} εγγραφές </div>
                <div className='grid'>
                  {data.map((item) => (
                    <div
                      className={`gridItem${selectedTagId === item.id ? ' selected' : ''}${props.modal === true ? ' small' : ''}`}
                      key={item.id}
                      value={item.id}
                      onClick={(e) => {
                        if (!props.modal) props.history.push(`/tags/${item.id}`);
                        if (props.modal && props.onSelect) {
                          //props.onSelect(`${item.id} - ${item.name}`);
                          setSelectedTagId(item.id);
                        }
                      }}>
                      {!props.modal && (
                        <>
                          <div className='gridItemMainRow'>{item.name}</div>
                          <div className='gridItemSubRow'>{item.info}</div>
                        </>
                      )}
                      {props.modal === true && (
                        <>
                          <div className='gridItemMainRow'>{item.name.toString().replace(`${props.tagTypeName} - `, '')}</div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {props.modal && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              <Button className='btn btn-danger btn-sm' style={{ margin: '4px 4px 4px 4px' }} onClick={() => props.appProps.setModalData(null)}>
                Άκυρο
              </Button>
              <Button
                disabled={!selectedTagId}
                className='btn  btn-sm'
                style={{ margin: '4px 0px 4px 4px' }}
                onClick={() => {
                  props.onSelect(selectedTagId);
                  props.appProps.setModalData(null);
                }}>
                Επιλογή
              </Button>
            </div>
          )}
        </div>
      )}

      {tag && (
        <div style={{ flexGrow: '1', display: 'flex', height: '100%', overflowY: 'hidden', flexDirection: 'column' }}>
          <div style={{ padding: '15px', flexGrow: '1', overflowY: 'auto' }}>
            <Form>
              <Form.Group>
                <Form.Control
                  style={{ marginBottom: '10px' }}
                  placeholder='Name'
                  type='text'
                  readOnly={readonly}
                  onChange={(element) => {
                    var o = { ...tag };
                    o.name = element.target.value;

                    setTag(o);
                  }}
                  value={tag.name}
                />
              </Form.Group>

              <RcSelect
                placeholder='Τύπος'
                onChange={handleSelectChange}
                name='type_id'
                //isDisabled={true}
                valueProperty='id'
                labelProperty='name'
                isClearable={false}
                value={tag.type_id}
                //options={symbasiouxoiList}
                options={tagsTypes}
                isDisabled={readonly}></RcSelect>

              <Form.Control
                style={{ marginBottom: '10px' }}
                placeholder='Data'
                type='text'
                readOnly={readonly}
                onChange={(element) => {
                  var o = { ...tag };
                  o.data = element.target.value;

                  setTag(o);
                }}
                value={tag.data}
              />

              <Form.Control
                style={{ marginBottom: '10px' }}
                placeholder='Info'
                type='text'
                readOnly={readonly}
                onChange={(element) => {
                  var o = { ...tag };
                  o.info = element.target.value;

                  setTag(o);
                }}
                value={tag.info}
              />

              <Tag {...props} isDisabled={readonly} value={tag.tags} name='tags' onChange={handleSelectChange} />

              <OrderList collapsed={false} title='Σχετικά Άρθρα'>
                {tag.articles &&
                  tag.articles.map((article, index) => (
                    <OrderListItem
                      name={article.title}
                      id={article.id}
                      list={tag.articles}
                      item={article}
                      showIds
                      onOrderClick={(isUp) => {
                        orderItem(tag_id, isUp, article.id, null);
                      }}
                    />
                  ))}
              </OrderList>

              {tagsTypes &&
                tag.fromTags &&
                tagsTypes
                  .filter((i) => tag.fromTags.filter((tt) => tt.typeId === i.id).length > 0)
                  .map((g, index) => (
                    <OrderList
                      key={g.id}
                      refresh={() => loadtag(null, tag_id)}
                      collapsed={true}
                      title={g.pName === 'Γενικές Ετικέτες' ? 'Ετικέτες' : g.pName ?? g.name}
                      tagId={tag_id}
                      tagType={g.id}
                      tagTypeName={g.name}
                      editable={true}>
                      {tag.fromTags &&
                        tag.fromTags
                          .filter((i) => i.typeId === g.id)
                          .map((fromTag, index) => (
                            <OrderListItem
                              editable={true}
                              listName={g.pName === 'Γενικές Ετικέτες' ? 'Ετικέτες' : g.pName ?? g.name}
                              removable
                              name={fromTag.name}
                              id={fromTag.id}
                              listTagId={tag_id}
                              refresh={() => loadtag(null, tag_id)}
                              onOrderClick={(isUp) => {
                                orderItem(tag_id, isUp, null, fromTag.id);
                              }}
                            />
                          ))}
                    </OrderList>
                  ))}
            </Form>
          </div>
          <div style={{ paddingLeft: '5px', flexShrink: '1', backgroundColor: '#eee', boxShadow: '0 0 5px #555' }}>
            <Button className='btn btn-info btn-sm' style={{ margin: '4px' }} onClick={() => props.history.push(`/tags`)}>
              Επιστροφή
            </Button>
            <Button
              className='btn btn-info btn-sm'
              style={{ margin: '4px' }}
              onClick={() => {
                setReadonly(!readonly);
              }}>
              {readonly ? 'Επεξεργασία' : 'Απενεργοποίηση Επεξεργασίας'}
            </Button>

            {!readonly && (
              <>
                <Button
                  className='btn btn-success btn-sm'
                  style={{ margin: '4px' }}
                  onClick={() => {
                    savetag((id) => {
                      if (tag_id === 0) props.history.push(`/tags/${id}`);
                    });
                  }}>
                  Αποθήκευση
                </Button>
                <Button
                  className='btn btn-success btn-sm'
                  style={{ margin: '4px' }}
                  onClick={() => {
                    savetag(() => {
                      props.history.push(`/tags`);
                    });
                  }}>
                  Αποθήκευση και Επιστροφή
                </Button>
                <Button
                  className='btn btn-danger btn-sm'
                  style={{ margin: '4px' }}
                  onClick={() => {
                    props.appProps.setModalData({
                      Title: 'Επιβεβαίωση Διαγραφής',
                      SubTitle: `Να πραγματοποιηθεί η διαγραφή της εγγραφής`,
                      isQuestion: true,
                      handleConfirm: () => {
                        deletetag(() => {
                          props.history.push(`/tags`);
                        });
                      },
                    });
                  }}>
                  Διαγραφή
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Tags;
