import React, { useState } from 'react';
import Article from '../Article';
import { BsArrowDownSquare, BsArrowUpSquare } from 'react-icons/bs';

export default function RcAccordion(props) {
  const [expanded, setExpanded] = useState(props.expanded ?? false);

  const showTitle = () => {
    if (props.title === undefined && props.expanded) return false;
    else return true;
  };

  return (
    <div style={{ padding: '0px' }}>
      {showTitle() && (
        <div
          style={{
            display: 'flex',
            justifyContent:"space-between",
            cursor: 'pointer',
            backgroundColor: '#eeeeee',
            minHeight: '40px',
            borderRadius: expanded ? '5px 5px 0px 0px' : '5px',
            border: 'solid 1px lightgray',
            padding: '8px',
          }}
          onClick={() => setExpanded(!expanded)}>
          <div style={{display: 'flex',flexDirection:"row", justifyItems:"space-between", flexGrow:"1"}}>
            <div style={{ flexGrow:"1"}}>{props.title}</div>
            <div style={{ fontSize: '9px', lineHeight: '19px', opacity: '.4', paddingRight:"10px" }}>{props.id}</div>
          </div>
          <div>
            {expanded && <BsArrowUpSquare style={{ float: 'right', margin: '4px', color: '#888888' }} />}
            {!expanded && <BsArrowDownSquare style={{ float: 'right', margin: '4px', color: '#888888' }} />}
          </div>
        </div>
      )}
      {expanded && (
        <div style={{ minHeight: '100px', borderRadius: '0px 0px 5px 5px', border: showTitle() ? 'solid lightgray' : 'none', borderWidth: '0px 1px 1px 1px' }}>
          <Article parent={props.parent} id={props.id} appProps={props.appProps} hideTitle={true} padding={showTitle() ? '20px' : '0px'} />
        </div>
      )}
    </div>
  );
}
