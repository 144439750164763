import React, { useState, useRef, useEffect, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Tag from './TagControl';
import RcSelect from './RcSelect_v.2';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Article from '../Article';

function Articles(props) {
  const editor = useRef(null);
  const [readonly, setReadonly] = useState(true);
  const [article, setArticle] = useState(null);
  const [content, setContent] = useState(null);
  const [changed, setChanged] = useState(null);
  const [versions, setVersions] = useState(null);

  const [article_id, setAarticle_id] = useState(null);

  const [config, setConfig] = useState({
    readonly: true, // all options from https://xdsoft.net/jodit/doc/
    toolbar: false,
    hidePoweredByJodit: true,
    spellcheck: true,
    uploader: {
      insertImageAsBase64URI: true,
    },
  });

  useEffect(() => {
    setConfig({
      readonly: readonly, // all options from https://xdsoft.net/jodit/doc/
      toolbar: !readonly,
      spellcheck: true,
      hidePoweredByJodit: true,
      uploader: {
        insertImageAsBase64URI: true,
      },
    });
  }, [readonly]);

  ///////////////////////////////////////////////////////////////

  const DataUrl = props.appProps.rootUrl + '&Parameters=Query:articles_list';

  const [data, setData] = useState(null);
  const [filter, setFilter] = useState('');
  const [tagsFilter, setTagsFilter] = useState([]);
  const [tagsTypeFilter, setTagsTypeFilter] = useState('');
  const [tagsTypes, setTagsTypes] = useState(null);

  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    if (!readonly && changed === true) {
      console.log(readonly, changed, 'addEventListener');
      window.addEventListener('beforeunload', alertUser);
    } else {
      if (readonly) setChanged(null);
      console.log(readonly, changed, 'removeEventListener');

      window.removeEventListener('beforeunload', alertUser);
    }

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changed, readonly]);

  const alertUser = (event) => {
    console.log(readonly, changed);

    if (window.confirm('Να αγνοηθούν οι αλλαγές δεν έχουν αποθηκευτεί;')) {
    } else {
      event.preventDefault();
    }

    event.preventDefault();
    event.returnValue = 'test';
  };

  useEffect(() => {
    if (tagsTypes === null) {
      var url = props.appProps.rootUrl + `&Parameters=Query:tags_types`;
      axios(url).then((response) => setTagsTypes(response.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsTypes]);
  ////////////////////////////////////////

  useEffect(() => {
    if (article_id == null) {
      setContent('');
      setChanged(null);
      setArticle(null);
      loadList();
    } else if (article_id > 0) {
      loadArticle(article_id);
    } else if (article_id === 0) {
      setContent('');
      setChanged(null);
      setArticle({ id: 0 });
      setVersions([]);
      setReadonly(false);
    }
  }, [article_id, filter, tagsFilter, tagsTypeFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!props.match.params.id) {
    if (article_id !== null) {
      setAarticle_id(null);
    }
  } else if (props.match.params.id === '0') {
    if (article_id !== 0) {
      setAarticle_id(0);
      if (readonly) setReadonly(0);
    }
  } else if (props.match.params.id !== '0') {
    if (article_id == null || article_id !== parseInt(props.match.params.id)) {
      setAarticle_id(parseInt(props.match.params.id));
    }
  }

  const [delayLoad, setDelayLoad] = useState();

  const loadList = () => {
    clearTimeout(delayLoad);

    setDelayLoad(
      setTimeout(() => {
        let url = DataUrl;
        if (filter.trim(' ') !== '') url = url + ',filter:' + filter;
        if (tagsFilter && tagsFilter.length > 0) url = url + ',tagsfilter:' + tagsFilter.map((t) => t.id).join(';');
        if (tagsTypeFilter && tagsTypeFilter !== '') url = url + ',tagstypefilter:' + tagsTypeFilter;

        setSpinner(true);
        axios(url).then((response) => {
          setData(response.data);
          setSpinner(false);
        });
      }, 500)
    );
  };

  const loadArticle = (value, version) => {
    console.log('loadArticle:', 'id:', value, 'version:', version);

    var url = props.appProps.rootUrl + `&Parameters=Query:get_article,id:${value}`;

    if (version) url = url + `,version:${version}`;

    axios(url).then((response) => {
      setData(null);
      setArticle(response.data.Article);
      setContent(response.data.Article?.content);
      setChanged(null);
      setVersions(response.data.Versions);
    });
    // setId(id);
  };

  const saveArticle = (callback) => {
    var o = {
      EndPoint: 'Lndnyk0joJElqcnvIt-ve-fRLByNjYg_TZlqYy7Mz2A.',
      Data: {
        Parameters: [
          {
            Name: 'id',
            Value: article.id,
          },
          {
            Name: 'title',
            Value: article.title,
          },
          {
            Name: 'content',
            Value: content,
          },
          {
            Name: 'tags',
            Value: article.tags ? article.tags.map((o) => o.id).join(',') : '',
          },
        ],
      },
    };

    axios.post(props.appProps.rootPostUrl, o).then((res) => {
      props.appProps.setAlertData({
        type: 'success',
        Title: 'resCom',
        SubTitle: 'Ενημέρωση',
        Messages: [{ Text: 'Η αποθήκευση ολοκληρώθηκε με επιτυχία!' }],
      });
      console.log('id: ', res.data.id);
      if (callback) callback(res.data.id);
    });
  };

  const deleteArticle = async (callback) => {
    axios(props.appProps.rootUrl + `&Parameters=Query:delete_article,id:${article.id}`).then((response) => {
      setData(null);
      setArticle({ id: null });
      setContent(null);
      setChanged(null);
      props.appProps.setAlertData({
        type: 'success',
        Title: 'resCom',
        SubTitle: 'Ενημέρωση',
        Messages: [{ Text: 'Η διαγραφή ολοκληρώθηκε με επιτυχία!' }],
      });

      if (callback) callback();
    });
  };

  const deleteArticleCurrentVersion = (callback) => {
    var version = article.version;

    axios(props.appProps.rootUrl + `&Parameters=Query:delete_article_version,id:${article.id},version:${version}`).then((response) => {
      loadArticle(article.id);

      props.appProps.setAlertData({
        type: 'success',
        Title: 'resCom',
        SubTitle: 'Ενημέρωση',
        Messages: [
          {
            Text: `Η διαγραφή της έκδoσης ${version} ολοκληρώθηκε με επιτυχία!`,
          },
        ],
      });

      if (callback) callback();
    });
  };

  //if (props.url_article_id !== article_id) setAarticle_id(props.url_article_id);

  return (
    <div className='non-home-container'>
      {article === null && (
        <div style={{ padding: '15px', flexGrow: '1', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <div style={{ flexShrink: '1' }}>
            <Button className='btn btn-primary btn-sm float-right' style={{ marginBottom: '20px' }} type='button' onClick={(e) => props.history.push(`/articles/0`)}>
              Νέο Άρθρο
            </Button>
            <Form.Control
              style={{ marginBottom: '15px' }}
              placeholder='φίλτρο...'
              type='text'
              onChange={(event) => {
                setFilter(event.target.value);
              }}
              value={filter}
            />
            <RcSelect
              style={{ marginBottom: '20px' }}
              placeholder='με τύπο ετικέτας...'
              onChange={(a, b, c) => {
                setTagsTypeFilter(c);
              }}
              name='type_id'
              valueProperty='id'
              labelProperty='name'
              isClearable={true}
              value={tagsTypeFilter}
              options={tagsTypes}></RcSelect>
            <Tag
              {...props}
              placeholder='με ετικέτες...'
              style={{ marginBottom: '20px' }}
              value={tagsFilter}
              name='tags'
              onChange={(a, b, c) => {
                setTagsFilter(c);
              }}
            />
            <div style={{ width: '100%', textAlign: 'center', marginTop: '13px', opacity: '0.3' }}>{spinner && <PropagateLoader size='10px' />}</div>
            {data !== null && spinner === false && data.length === 0 && <p style={{ marginLeft: '3px', fontSize: '16px' }}>Δεν υπάρχουν εγγραφές </p>}
            {data === null && <p>Φόρτωση...</p>}
          </div>
          <div style={{ display: 'flex', flexGrow: '1', overflowY: 'hidden' }}>
            {data !== null && data.length !== 0 && (
              <div style={{ flexGrow: '1', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                <div style={{ flexShrink: '1', textAlign: 'end', margin: '5px', fontSize: '13px' }}>{data.length} εγγραφές </div>

                <div className='grid'>
                  {data.map((item) => (
                    <div className='gridItem' key={item.id} value={item.id} onClick={(e) => props.history.push(`/articles/${item.id}`)}>
                      <div className='gridItemMainRow'>{item.title}</div>
                      <div className='gridItemSubRow'>{item.info}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <div style={{ padding: '15px', flexGrow: '1', display: 'flex', flexDirection: 'column', overflow: 'hidden', border: '0px solid yellow' }}>
        <div style={{ flexShrink: '1', display: 'flex', flexDirection: 'column', border: '0px solid blue' }}>
          {article && versions !== null && (
            <>
              <div style={{ display: 'flex', alignSelf: 'end', paddingBottom: '10px' }}>
                {article.id > 0 && (
                  <div>
                    <div
                      onClick={() => {
                        console.log(article.id);
                        var url = props.appProps.rootUrl + `&Parameters=Query:get_anonymous_link,article:${article.id}`;
                        axios(url).then((response) => {
                          console.log(response.data);
                          if (response.data.link) {
                            navigator.clipboard.writeText(response.data.link).then(
                              function () {
                                console.log(`Copying link ${response.data.link} to clipboard was successful!`);
                                alert(`Το link ${response.data.link} αντιγράφηκε`)
                              },
                              function (err) {
                                console.error(`Could not copy link ${response.data.link} : `, err);
                                alert(`Το link ${response.data.link} δεν αντιγράφηκε`)
                              }
                            );
                          }
                        });
                      }}
                      style={{ paddingRight: '20px', cursor: 'pointer', color: 'blue' }}>
                      Ανώνυμο link
                    </div>
                  </div>
                )}
                <div>Κωδικός: {article.id}</div>
              </div>
              <Form.Group>
                <Form.Control
                  style={{ marginBottom: '10px' }}
                  placeholder='Τίτλος'
                  type='text'
                  readOnly={readonly}
                  onChange={(element) => {
                    var o = { ...article };
                    o.title = element.target.value;

                    setArticle(o);
                  }}
                  value={article.title}
                />
                <div style={{ marginBottom: '10px' }}>
                  <Tag
                    {...props}
                    isDisabled={readonly}
                    value={article.tags}
                    name='tags'
                    onChange={(a, b, c) => {
                      var o = { ...article };
                      o.tags = c;
                      setArticle(o);
                    }}
                  />
                </div>

                <div className='d-flex flex-row-reverse' style={{ marginBottom: '10px' }}>
                  <Button
                    className='btn btn-danger btn-sm'
                    style={{ marginLeft: '10px' }}
                    type='button'
                    onClick={() => {
                      props.appProps.setModalData({
                        Title: 'Επιβεβαίωση Διαγραφής Έκδοσης',
                        SubTitle: `Να πραγματοποιηθεί η διαγραφή της έκδοσης ${article.version}`,
                        isQuestion: true,
                        handleConfirm: () => {
                          deleteArticleCurrentVersion();
                        },
                      });
                    }}>
                    Διαγραφή
                  </Button>

                  <select
                    className='col-5 form-control form-control-sm'
                    style={{ maxWidth: '370px' }}
                    value={article.version}
                    onInput={(e, a) => {
                      loadArticle(article.id, e.target.value);
                    }}>
                    {versions.map((v) => (
                      <option value={v.version} key={v.id}>
                        {v.Label}
                      </option>
                    ))}
                  </select>
                  <div style={{ paddingTop: '4px', paddingRight: '10px' }}>Έκδοση</div>
                </div>
              </Form.Group>
            </>
          )}
        </div>
        <div style={{ flexGrow: '1', display: 'flex', flexDirection: 'column', overflowY: 'auto', border: `${readonly && article ? 1 : 0}px solid #bbb` }}>
          {useMemo(
            () => (
              <>
                {article && versions !== null && (
                  <>
                    {readonly && (
                      <Article key={`${article.id}-${article.version}`} id={article_id} appProps={props.appProps} hideTitle={true} padding='10px' version={article.version} />
                    )}
                    {!readonly && (
                      <JoditEditor
                        ref={editor}
                        key={`${article.id}-${article.version}`}
                        value={content}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onChange={(content) => {
                          setChanged((prev) => {
                            return prev === null ? false : true;
                          });

                          setContent(content);
                        }}
                        // onBlur={(newContent) => {
                        //  // setContent(newContent.target.innerHTML.replace(/<\/?jodit[^>]*?>/g, ''));
                        //   console.log(newContent.target.innerHTML)

                        //   var images = newContent.target.getElementsByTagName('img');

                        //   for (var i = 0; i < images.length; i++) {
                        //     var img = images[i];

                        //     console.log(img.src);
                        //     //img.src = URL.revokeObjectURL(img.src);
                        //   }

                        //   //console.log(tmp.innerHTML);
                        // }}
                      />
                    )}
                  </>
                )}
              </>
            ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [config, article, versions, changed]
          )}
        </div>
      </div>
      {article && versions !== null && (
        <>
          <div style={{ marginLeft: '5px' }}>
            <Button
              className='btn btn-info btn-sm'
              style={{ margin: '4px' }}
              onClick={() => {
                if (changed === true) {
                  props.appProps.setModalData({
                    Title: 'Επιβεβαίωση',
                    SubTitle: `Να αγνοηθούν οι αλλαγές δεν έχουν αποθηκευτεί;`,
                    isQuestion: true,
                    handleConfirm: () => {
                      setReadonly(true);
                      props.history.push(`/articles`);
                    },
                  });
                } else {
                  setReadonly(true);
                  props.history.push(`/articles`);
                }
              }}>
              Επιστροφή
            </Button>
            <Button
              className='btn btn-info btn-sm'
              style={{ margin: '4px' }}
              onClick={() => {
                if (changed === true) {
                  props.appProps.setModalData({
                    Title: 'Επιβεβαίωση',
                    SubTitle: `Να αγνοηθούν οι αλλαγές δεν έχουν αποθηκευτεί;`,
                    isQuestion: true,
                    handleConfirm: () => {
                      setReadonly(!readonly);
                    },
                  });
                } else {
                  setReadonly(!readonly);
                }
              }}>
              {readonly ? 'Επεξεργασία' : 'Απενεργοποίηση Επεξεργασίας'}
            </Button>

            {!readonly && (
              <>
                <Button
                  className='btn btn-success btn-sm'
                  style={{ margin: '4px' }}
                  onClick={() => {
                    if (article_id === 0) {
                      saveArticle((id) => {
                        props.history.push(`/articles/${id}`);
                      });
                    } else {
                      saveArticle((id) => {
                        loadArticle(id);
                      });
                    }
                  }}>
                  Αποθήκευση
                </Button>
                <Button
                  className='btn btn-success btn-sm'
                  style={{ margin: '4px' }}
                  onClick={() => {
                    saveArticle(() => {
                      props.history.push(`/articles`);
                      setReadonly(true);
                    });
                  }}>
                  Αποθήκευση και Επιστρόφή
                </Button>
                <Button
                  className='btn btn-danger btn-sm'
                  style={{ margin: '4px' }}
                  onClick={() => {
                    props.appProps.setModalData({
                      Title: 'Επιβεβαίωση Διαγραφής',
                      SubTitle: `Να πραγματοποιηθεί η διαγραφή της εγγραφής`,
                      isQuestion: true,
                      handleConfirm: () => {
                        deleteArticle(() => {
                          props.history.push(`/articles`);
                        });
                      },
                    });
                  }}>
                  Διαγραφή
                </Button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Articles;
