import { useEffect, useState } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import axios from 'axios';
import { AiFillFile } from 'react-icons/ai';

export default function Search(props) {
  const [filter, setFilter] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [data, setData] = useState(null);
  const [delayLoad, setDelayLoad] = useState();
  const DataUrl = window.appProps.rootUrl + '&Parameters=Query:articles_list';

  const loadList = () => {
    clearTimeout(delayLoad);

    setDelayLoad(
      setTimeout(() => {
        let url = DataUrl;
        url = url + ',filter:' + filter;

        setSpinner(true);
        axios(url).then((response) => {
          setData(response.data);
          setSpinner(false);
        });
      }, 500)
    );
  };

  useEffect(() => {
    if (filter.trim(' ') !== '') loadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <div className='search' onClick={() => props.setShowSearch(false)}>
      <div className='search-box' onClick={(e) => e.stopPropagation()}>
        <input
          type='text'
          autofocus
          className='search-box-input'
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
        <div className='search-box-spinner'>{spinner && <PropagateLoader size={10} />}</div>

        <div className='search-grid'>
          {data &&
            data.map((a) => (
              <div
                className='search-grid-item'
                onClick={() => {
                  props.setArticle(a.id);
                  props.setShowSearch(false)
                }}>
                <div className='main'>{a.title}</div>
                <div className='sub'>
                  <div className='icons'>
                    <div className='icon'>
                      <AiFillFile />
                    </div>
                    <div className='icon'>
                      <AiFillFile />
                    </div>
                    <div className='icon'>
                      <AiFillFile />
                    </div>
                  </div>
                  <div style={{ flexGrow: '1' }} />
                  {a.info}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
