import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';

function History(props) {
  const [data, setData] = useState(null);

  useEffect(() => {
    //axios.defaults.headers.common['Authorization'] = `Bearer ${props.match.params.auth}`;

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.historyDate]);

  const loadData = () => {
    console.log('Loaddings histrory...');
    let url = window.appProps.rootUrl + `&Parameters=Query:resComFormHistory`;
    axios(url).then((response) => {
      setData(response.data);
      props.loadEnd();
    });
  };

  return (
    <div className='non-home-container'>
      {data && (
        <div className='history'>
          <div className='history-title'>{data.info.title}</div>
          <div className='history-body'>
            {data.history.map((item, index) => (
              <div key={index} className={`history-item type-${item.historyTypeId}`}>
                <div className='history-item-row first'>
                  <div className='history-item-data historyTypeName'>{item.historyTypeName} </div>

                  <div className='history-item-data date'>{item.date ? format(new Date(item.date), 'dd/MM/yyyy HH:mm:ss') : ''}</div>
                </div>
                <div className='history-item-row second'>
                  <div className='history-item-data typeName'>
                    {item.typeName} - {item.name}
                  </div>

                  <div className='history-item-data userFullName'>{item.userFullName}</div>
                </div>
              </div>
            ))}
          </div>
          <br />
        </div>
      )}
    </div>
  );
}

export default History;
