import './App.css';
import Article from './Article.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { BiSearch } from 'react-icons/bi';

import Home from './components/Home';
import Articles from './components/Articles';
import Tags from './components/Tags';
import LoginForm from './components/LoginForm';
import RcAlert from './components/RcAlert';
import RcModal from './components/RcModal';
import ResCom from './components/ResCom';
import Search from './components/Search';

function App() {
  const [loginData, setLoginData] = useState(undefined);
  const [alertData, setAlertData] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [article_id, setArticle_id] = useState(null);

  var appProps = {
    loginData: null,
    setLoginData: null,
    modalData: null,
    setModalData: null,
    alertData: null,
    setAlertData: null,
    rootUrl: `${process.env.REACT_APP_API_URL}/exec/?id=RA`,
    rootPostUrl: `${process.env.REACT_APP_API_URL}/Xp`,
  };

  appProps.loginData = loginData;
  appProps.setLoginData = setLoginData;
  appProps.modalData = modalData;
  appProps.setModalData = setModalData;
  appProps.alertData = alertData;
  appProps.setAlertData = setAlertData;
  window.appProps = appProps;

  if (loginData?.Auth) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${loginData.Auth}`;
  }

  useEffect(() => {
    if (loginData === undefined) {
      let loginDataStr = localStorage.getItem('resComArticlesloginData');
      try {
        if (loginData?.Auth) axios.defaults.headers.common['Authorization'] = `Bearer ${loginData.Auth}`;

        setLoginData(JSON.parse(loginDataStr));
      } catch (error) {
        setLoginData(null);
      }
    } else {
      let SavedloginDataStr = localStorage.getItem('resComArticlesloginData');
      if (SavedloginDataStr !== JSON.stringify(loginData)) {
        localStorage.setItem('resComArticlesloginData', JSON.stringify(loginData));
      }
    }
  }, [loginData]);

  var url_article_id;
  if (window.location.href && window.location.href.split('id=').length > 0 && window.location.href.split('id=')[1]) {
    url_article_id = window.location.href.split('id=')[1].split('&')[0].split('#')[0];
  }

  if (url_article_id) {
    return (
      <div className='single-article'>
        <Article appProps={appProps} />
      </div>
    );
  } else {
    if (loginData === null && !window.location.href.includes('/rescom/')) {
      return (
        <div className='content-wrapper' style={{ marginLeft: '0px', justifyContent: 'center' }}>
          <LoginForm appProps={appProps} />
        </div>
      );
    }

    return (
      <div className='app-container'>
        <RcModal ModalData={modalData} setModalData={setModalData} />
        <RcAlert AlertData={alertData} setAlertData={setAlertData} />
        {showSearch && <Search setShowSearch={setShowSearch} setArticle={setArticle_id}/>}
        {(loginData !== undefined || window.location.href.includes('/rescom/')) && (
          <BrowserRouter>
            {!window.location.href.includes('/rescom/') && (
              <div className='nav-bar'>
                <a className='nav-item logo' href='/'>
                  <b style={{ color: '#233c5d' }}>resCom</b>
                  <span style={{ color: '#2458bb' }}>Articles</span>
                </a>

                <a className='nav-item' href='/articles'>
                  <span style={window.location.href.toLowerCase().includes('/articles') ? { color: 'blue', textShadow: '1px 1px 3px #00000055' } : {}}>ΑΡΘΡΑ</span>
                </a>
                <a className='nav-item' href='/tags'>
                  <span style={window.location.href.toLowerCase().includes('/tags') ? { color: 'blue', textShadow: '1px 1px 3px #00000055' } : {}}>ΕΤΙΚΕΤΕΣ</span>
                </a>
                <div className='nav-item' style={{ flexGrow: '1' }}></div>
                <div
                  className='nav-item search-bar'
                  onClick={() => {
                    //setShowSearch((prev) => !prev);
                  }}>
                  <span className='search-placeholder'>Αναζήτηση...</span>
                  <BiSearch className='search-icon' />
                </div>

                <div className='nav-item'>
                  <div className='dropdown-toggle' id='navbarDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    {loginData.Ονοματεπώνυμο?.split(' ')[0]?.substring(0, 1)}
                    {loginData.Ονοματεπώνυμο?.split(' ')[1]?.substring(0, 1)}
                  </div>
                  <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                    <div
                      className='dropdown-item'
                      onClick={() => {
                        localStorage.setItem('resComArticlesloginData', null);
                        setLoginData(null);
                      }}>
                      Αποσύνδεση
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Switch>
              <Route exact path='/' render={(props) => <Home appProps={appProps} {...props} />} />
              <Route exact path='/nav/:id' render={(props) => <Home article_id={article_id}  appProps={appProps} {...props} />} />
              

              <Route exact path='/articles' render={(props) => <Articles appProps={appProps} {...props} />} />
              <Route exact path='/articles/:id' render={(props) => <Articles appProps={appProps} {...props} />} />

              <Route exact path='/tags' render={(props) => <Tags appProps={appProps} {...props} />} />
              <Route exact path='/tags/:id' render={(props) => <Tags appProps={appProps} {...props} />} />

              <Route exact path='/rescom/:auth' render={(props) => <ResCom appProps={appProps} {...props} />} />
            </Switch>
          </BrowserRouter>
        )}
      </div>
    );
  }
}
export default App;

/////////////////////////////////////////////////

var x, i, j, l, ll, selElmnt, a, b, c;
/* Look for any elements with the class "custom-select": */
x = document.getElementsByClassName('custom-select');
l = x.length;
for (i = 0; i < l; i++) {
  selElmnt = x[i].getElementsByTagName('select')[0];
  ll = selElmnt.length;
  /* For each element, create a new DIV that will act as the selected item: */
  a = document.createElement('DIV');
  a.setAttribute('class', 'select-selected');
  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
  x[i].appendChild(a);
  /* For each element, create a new DIV that will contain the option list: */
  b = document.createElement('DIV');
  b.setAttribute('class', 'select-items select-hide');
  for (j = 1; j < ll; j++) {
    /* For each option in the original select element,
    create a new DIV that will act as an option item: */
    c = document.createElement('DIV');
    c.innerHTML = selElmnt.options[j].innerHTML;
    c.addEventListener('click', function (e) {
      /* When an item is clicked, update the original select box,
      and the selected item: */
      var y, i, k, s, h, sl, yl;
      s = this.parentNode.parentNode.getElementsByTagName('select')[0];
      sl = s.length;
      h = this.parentNode.previousSibling;
      for (i = 0; i < sl; i++) {
        if (s.options[i].innerHTML === this.innerHTML) {
          s.selectedIndex = i;
          h.innerHTML = this.innerHTML;
          y = this.parentNode.getElementsByClassName('same-as-selected');
          yl = y.length;
          for (k = 0; k < yl; k++) {
            y[k].removeAttribute('class');
          }
          this.setAttribute('class', 'same-as-selected');
          break;
        }
      }
      h.click();
    });
    b.appendChild(c);
  }
  x[i].appendChild(b);
  a.addEventListener('click', function (e) {
    /* When the select box is clicked, close any other select boxes,
    and open/close the current select box: */
    e.stopPropagation();
    closeAllSelect(this);
    this.nextSibling.classList.toggle('select-hide');
    this.classList.toggle('select-arrow-active');
  });
}

function closeAllSelect(elmnt) {
  /* A function that will close all select boxes in the document,
  except the current select box: */
  var x,
    y,
    i,
    xl,
    yl,
    arrNo = [];
  x = document.getElementsByClassName('select-items');
  y = document.getElementsByClassName('select-selected');
  xl = x.length;
  yl = y.length;
  for (i = 0; i < yl; i++) {
    if (elmnt === y[i]) {
      arrNo.push(i);
    } else {
      y[i].classList.remove('select-arrow-active');
    }
  }
  for (i = 0; i < xl; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add('select-hide');
    }
  }
}

/* If the user clicks anywhere outside the select box,
then close all select boxes: */
document.addEventListener('click', closeAllSelect);
