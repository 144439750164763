import React from "react";
import AsyncSelect from "react-select/async";
import axios from "axios";

export default function Tag(props) {
  const filterTags = async (inputValue) => {
    console.log(inputValue, props.appProps.rootUrl);
    var url = props.appProps.rootUrl + `&Parameters=Query:tags_list,short:1,filter:${inputValue}`;
    const response = await axios(url);
    return response.data.map(o => ({ label: o.name, value: o.id }));
  };

  const styles = {

    control: base => ({
      ...base, 
      border: "1", 
      boxShadow: "none",

      
      minHeight: 38,
      marginBottom: 15,
      borderRadius:0
  }),

    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterTags(inputValue));
      }, 100);
    });

  const onChange = (a, b, c, d) => {

    console.log('tagcontrol onChange:', a, b, c, d);
    props.onChange(null, b.name, a.map(o => ({ id: o.value, name: o.label })));
  };


  return (
    <AsyncSelect
      {...props}
      
      value={ props.value ? props.value.map(o => ({ label: o.name, value: o.id })) : []}
      isMulti
      styles={styles}
      cacheOptions
      loadOptions={promiseOptions}
      placeholder={props.placeholder ? props.placeholder :"Ετικέτες..."}
      onChange={onChange}
      
    />
  );
}
