import React, { Component } from 'react';
import { Alert, AlertContainer } from "react-bs-notifier";

class RcAlert extends Component {

  render() {
    let index = 1;
    let type = "danger";
    if (this.props.AlertData && this.props.AlertData.type) {
      type = this.props.AlertData.type
    }

    //console.log('alertData:', this.props)

    return (
      <AlertContainer position="top-left">
        {this.props.AlertData !== null &&
          <Alert
            type={type}

            timeout={type === "danger" ? 0 : 3000}
            headline={this.props.AlertData.SubTitle}
            onDismiss={() => this.props.setAlertData(null)}
          >
            {this.props.AlertData !== null && this.props.AlertData.Messages !== undefined && this.props.AlertData.Messages.map(m =>
              <div key={index++}><hr />
                <p>{m.Text}</p>
                <p className="font-weight-light">{m.AdditionalInfo}</p>
                <p className="text-right font-weight-light"><i>{m.Title}</i></p>
              </div>
            )}
          </Alert>
        }
      </AlertContainer >

    );
  }
}



export default RcAlert;

