import React from 'react';
import Select from 'react-select'



export default function RcSelect(props) {


    let placeholder = props.placeholder ? props.placeholder : 'Επιλέξτε...';
    let options
    if (props.options) options = props.options.map(item => { return { value: item[props.valueProperty ? props.valueProperty : 'value'], label: item[props.labelProperty ? props.labelProperty : 'label'] } });

    if (options && options.length > 0) {
        const firstOption = options[0].value;
        if (!firstOption || firstOption === null || firstOption.toString() === '0' || firstOption.toString().trim() === '') {
            placeholder = props.options[0][props.labelProperty ? props.labelProperty : 'label']
            options.shift();
        }
    }



    function getValue(value) {

        let returnValue = undefined;

        if (value === null)
            returnValue = null;
        else
            if (options) {
                if (value) {
                    const found = options.find(item => (item.value ? item.value.toString() : undefined) === (value ? value.toString() : undefined));
                    if (found) {
                        returnValue = found;
                    }
                }
            }

        return returnValue;
    }

    function onChange(a, b) {
        props.onChange(null, b.name, a ? a.value : undefined, props['data-dyntype'])
    }



    return (<div className="custom-form-control"><Select
        {...props}
        value={getValue(props.value)}
        options={options}
        styles={customStyles}
        theme={theme => ({ ...theme, borderRadius: 4 })}
        placeholder={placeholder}
        onChange={onChange}
    /></div>)

}
const customStyles = {
    placeholder:base => ({
        ...base, 
        opacity:"0.6"
    }),

    control: base => ({
        ...base, 
        border: "1", 
        boxShadow: "none",
        
        minHeight: 38,
        marginBottom: 15,
        borderRadius:0
    }),

    // option: (provided, state) => ({
    //     ...provided,
    //     borderBottom: '1px dotted pink',
    //     color: state.isSelected ? 'red' : 'blue',
    //     padding: 20,
    // }),
    // control: () => ({
    //     // none of react-select's styles are passed to <Control />
    //     width: 200,
    // }),
    // singleValue: (provided, state) => {
    //     const opacity = state.isDisabled ? 0.5 : 1;
    //     const transition = 'opacity 300ms';

    //     return { ...provided, opacity, transition };
    // }
}
