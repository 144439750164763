import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RcAccordion from './components/RcAccordion';
import PropagateLoader from 'react-spinners/PropagateLoader';

function Article(props) {
  const [article, setArticle] = useState(null);
  const [article_id, setArticle_id] = useState(null);

  useEffect(() => {
    if (props.id?.toString() !== article_id?.toString()) {
      setArticle_id(props.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  useEffect(() => {
    if (article_id !== null && article_id !== undefined) {
      //setTimeout(function(){}, 5000);
      setArticle(null);
      var url = props.appProps.rootUrl + `&Parameters=Query:get_article,short:1,id:${article_id},version:${props.version}${props.parent ? `,parent:${props.parent}` : ''}`;
      axios(url).then((response) => setArticle(response.data.Article));
    }
  }, [article_id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (article_id === null && props.id === undefined && window.location.href && window.location.href.split('id=').length > 0 && window.location.href.split('id=')[1]) {
    setArticle_id(window.location.href.split('id=')[1].split('&')[0]);
  }

  var body = '';
  if (article && article.content != null) {
    body = article.content;

    if (body.includes('#Περιεχόμενα')) {
      if (body.includes('#Περιεχόμενα_1')) {
        body = body.replace('#Περιεχόμενα_1', '<div class="___tocs"></div>');
        body = toc(body, 1, article.id);
      }
      if (body.includes('#Περιεχόμενα_2')) {
        body = body.replace('#Περιεχόμενα_2', '<div class="___tocs"></div>');
        body = toc(body, 2, article.id);
      }
      if (body.includes('#Περιεχόμενα_3')) {
        body = body.replace('#Περιεχόμενα_3', '<div class="___tocs"></div>');
        body = toc(body, 3, article.id);
      }
      if (body.includes('#Περιεχόμενα_4')) {
        body = body.replace('#Περιεχόμενα_4', '<div class="___tocs"></div>');
        body = toc(body, 4, article.id);
      }

      if (body.includes('#Περιεχόμενα_5')) {
        body = body.replace('#Περιεχόμενα_5', '<div class="___tocs"></div>');
        body = toc(body, 5, article.id);
      }
    }

    body = body.split('font-size').join('fs');
    body = body.split('font-family').join('fm');
    body = body.split('&nbsp;').join(' ');

    body = body.split('width').join('w');

    body = body.split('nowrap').join('');

    const re = /#{[^#]*\}#/g;

    var segments = body.split(re);
    var links = [];

    if (segments.length > 1)
      body.match(re).forEach((m) => {
        var r = m.split('#').join('');

        var div = document.createElement('div');
        div.innerHTML = r;
        r = div.innerText;

        try {
          const ob = JSON.parse(r);
          links = [...links, ob];
        } catch (error) {
          links = [...links, {}];
        }
      });
  }

  return (
    <div id='arcticlConatainer' style={{ padding: props.padding ? props.padding : '20px' }}>
      
        {/* <button
          onClick={() => {
            console.log(document.getElementById('arcticlConatainer'));

            if (document.getElementById('arcticlConatainer').parentElement.parentElement.scrollTop !== 0)
              document.getElementById('arcticlConatainer').parentElement.parentElement.scrollTo({ top: 0, behavior: 'smooth' });
            if (document.getElementById('arcticlConatainer').parentElement.scrollTop !== 0)
              document.getElementById('arcticlConatainer').parentElement.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          id='scrollToTopButton'
          title='Go to top'>
          Top
        </button> */}
      
      {article && article !== null && (
        <>
          {!props.hideTitle && (
            <div style={{ borderBottom: 'solid 1px darkgray', marginBottom: '20px' }}>
              <h4>{article.title}</h4>
            </div>
          )}
          {segments.map((s, index) => {
            return (
              <div key={index}>
                <div dangerouslySetInnerHTML={{ __html: s }} />
                {links[index] && (
                  <RcAccordion
                    appProps={props.appProps}
                    id={links[index].id}
                    title={links[index].title}
                    expanded={links[index].expanded}
                    parent={
                      window.location.href && window.location.href.split('id=').length > 0 && window.location.href.split('id=')[1]
                        ? window.location.href?.split('id=')[1].split('&')[0]
                        : undefined
                    }
                  />
                )}
              </div>
            );
          })}
        </>
      )}
      {(article === null || article === undefined) && (
        <div style={{ minHeight: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <PropagateLoader color='#55555522' speedMultiplier='0.8' loading={true} size={10} />
        </div>
      )}
    </div>
  );
}

export default Article;

const toc = (body, h = 4, article_id = 0) => {
  var toc = document.createElement('div');
  toc.innerHTML = body.trim();

  //Add a header
  let tocHeader = document.createElement('h2');
  tocHeader.innerText = 'Περιεχόμενα';

  let tocList = document.createElement('ul');

  let headers = toc.getElementsByTagName(`h${h}`);

  for (let i = 0; i < headers.length; i++) {
    let name = `_TOC_${article_id}_` + i;
    headers[i].id = name;

    let tocListItem = document.createElement('li');

    let tocEntry = document.createElement('a');
    tocEntry.setAttribute('href', '#' + name);
    tocEntry.innerText = headers[i].innerText;

    tocListItem.appendChild(tocEntry);
    tocList.appendChild(tocListItem);
  }
  console.log(toc);

  toc.getElementsByClassName('___tocs')[0].appendChild(tocHeader);
  toc.getElementsByClassName('___tocs')[0].appendChild(tocList);

  return toc.innerHTML; //.replace('#Περιεχόμενα', '<h1>'+ tocHeader.innerHTML + tocList.innerHTML);
};
