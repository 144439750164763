import React, { useState, useEffect, Fragment } from 'react';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import Article from '../Article';

function Home(props) {
  const [menu, setMenu] = useState(null);
  const [tag_id, setTag_id] = useState(null);
  const [article_id, setArticle_id] = useState(null);
  const [articles, setArticles] = useState(null);
  const [filter, setFilter] = useState(null);
  const [searchResalts, setSearchResalts] = useState(null);
  const [article, setArticle] = useState(null);

  useEffect(() => {
    if (props.article_id) {
      // setTag_id(null);
      // setArticles(null);
      props.history.push(`/nav/a${props.article_id}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (filter !== null && filter.toString().trim() !== '') {
      if (filter.toString().length > 2) {
        let url = props.appProps.rootUrl + `&Parameters=Query:search,filter:${filter}`;
        axios(url).then((response) => {
          console.log(filter);
          setSearchResalts(response.data);
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (tag_id === null && menu !== null && article_id === null) {
      props.history.push(`/nav/${menu[0].items[0].id}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu]);

  useEffect(() => {
    if (menu === null) {
      let url = props.appProps.rootUrl + '&Parameters=Query:menu';
      axios(url).then((response) => {
        setMenu(response.data);
      });
    }
  }, [menu]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tag_id !== null) {
      let url = props.appProps.rootUrl + `&Parameters=Query:menu_articles,tag:${tag_id}`;
      axios(url).then((response) => {
        setArticles(response.data);
      });
    }
  }, [tag_id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!props.match.params.id) {
    if (tag_id !== null) setTag_id(null);
    if (article_id !== null) setArticle_id(null);
  } else {
    if (props.match.params.id.startsWith('a')) {
      if (tag_id !== null) setTag_id(null);
      if (articles !== null) setArticles(null);
      if (article_id !== props.match.params.id.substring(1)) {
        setArticle_id(props.match.params.id.substring(1));
        
      }
    } else {
      if (article_id !== null) setArticle_id(null);
      if (tag_id !== props.match.params.id) {
        setTag_id(props.match.params.id);
   
      }
    }
  }

  return (
    <div className='home-container'>
      <div className='home-left-bar'>
        <ul className='nav flex-column'>
          <div>
            {menu && (
              <Accordion defaultActiveKey={tag_id !== null ? menu.findIndex((m) => m.items.filter((s) => s.id.toString() === tag_id.toString()).length > 0).toString() : '0'} flush>
                <>
                  {menu &&
                    menu.map((item, index) => (
                      <Accordion.Item eventKey={index.toString()} id={index} key={index}>
                        <Accordion.Header>
                          <div style={{ margin: '0 0 0 -10px', padding: '0px' }}>{item.name}</div>
                 
                        </Accordion.Header>
                        <Accordion.Body>
                          {item.items &&
                            item.items.map((subitem) => (
                              <li key={subitem.id} style={{ margin: '0 0 5px -10px' }}>
                                <button
                                  style={{
                                    padding: '0',
                                    textAlign: 'left',
                                    boxShadow: 'none',
                                    fontWeight: tag_id !== null ? (tag_id.toString() === subitem.id.toString() ? 'bold' : 'normal') : '0',
                                  }}
                                  className='nav-link btn btn-link'
                                  onClick={() => props.history.push(`/nav/${subitem.id}`)}>
                                  <span data-feather='{subitem.id}'></span>
                                  {subitem.name}
                                </button>
                              </li>
                            ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </>
              </Accordion>
            )}
          </div>
        </ul>
      </div>
      <div className='home-content'>
        {articles && articles.length > 1 && (
          <div>
            <Accordion defaultActiveKey='0'>
              <>
                {articles.map((item) => (
                  <Accordion.Item eventKey={item.id}>
                    <Accordion.Header 
                      onClick={() => {
                        setArticle(item.id);
                      }}>
                      {item.title}
                      <a href={`/articles/${item.id}`} target="_blank" rel="noreferrer"

                        

                      style={{ paddingLeft:"10px", textDecoration:"unset", fontSize: '12px', lineHeight: '2px', float: 'left', opacity: '.7' }}>(edit)</a>

                      
                    </Accordion.Header>
                    <Accordion.Body>
                      {article && item.id === article && <Article key={item.id} appProps={props.appProps} id={article} hideTitle={true} padding='0px' />}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </>
            </Accordion>
          </div>
        )}

        {article_id && <Article key={article_id} appProps={props.appProps} id={article_id} padding='0px' />}

        {articles && articles.length === 1 && (
          <div>
            {articles && (
              <>
                {articles.map((article) => (
                  <>
                    <div key={article.id} className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom'>
                      <h1 className='h2'>{article.title}</h1>
                    </div>

                    <div dangerouslySetInnerHTML={{ __html: article.text }} />
                  </>
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
