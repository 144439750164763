import React, { useState, useEffect } from 'react';

import { OrderListItem, OrderList } from './OrderList';
import axios from 'axios';
import Article from '../Article';
import History from './History';
import { BsPlusSquare } from 'react-icons/bs';
import Tags from './Tags';
import { set } from 'date-fns';

function ResCom(props) {
  const [data, setData] = useState(null);
  const [article, setArticle] = useState(null);
  const [expandedTagGroup, setExpandedTagGroup] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [historyDate, setHistoryDate] = useState(false);

  useEffect(() => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${props.match.params.auth}`;
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.auth]);

  const loadData = () => {
    let url = props.appProps.rootUrl + `&Parameters=Query:resComForm`;

    axios(url).then((response) => {
      setData(response.data);
      if (article === null && !showHistory && response.data.Άρθρα.length > 0) setArticle(response.data.Άρθρα[0]);
      if (showHistory) setHistoryDate(new Date());
    });
  };

  return (
    <div className='rescom-container'>
      <div className='rescom-top-panel'></div>

      <div className='rescom-body-panel'>
        <div className='rescom-left-panel'>
          <div className='rescom-left-panel-top'>
            <div>
              {data &&
                data.Άρθρα.map((item) => (
                  <div
                    className={`rescom-left-panel-item${article?.id === item.id ? ' selected' : ''}`}
                    key={item.id}
                    onClick={() => {
                      if (article?.id !== item.id) setArticle(item);
                      var myDiv = document.getElementsByClassName('rescom-main-panel')[0];

                      myDiv.scrollTop = 0;
                    }}>
                    {item.title}
                  </div>
                ))}
            </div>
          </div>
          <div className='rescom-left-panel-bottom'>
            {data && data.tagsTypes != null && data.tagsTypes.length > 0 && data.Ετικέτες && data.Ετικέτες.length > 0 && (
              <>
                {data.tagsTypes
                  .filter((i) => data.Ετικέτες.filter((tt) => tt.typeId === i.id).length > 0)
                  .map((g, index) => (
                    <OrderList
                      collapsed={expandedTagGroup !== g.id}
                      setExpandedId={setExpandedTagGroup}
                      leftBar
                      key={g.id}
                      refresh={() => loadData()}
                      title={g.pName === 'Γενικές Ετικέτες' ? 'Ετικέτες' : g.pName ?? g.name}
                      tagType={g.id}
                      tagId={-1}
                      tagTypeName={g.name}
                      editable={data.resComInfo.isEditor}>
                      {data.Ετικέτες &&
                        data.Ετικέτες
                          .filter((i) => i.typeId === g.id)
                          .map((fromTag, index) => (
                            <OrderListItem
                              removable
                              small
                              articles={fromTag.articles}
                              editable={data.resComInfo.isEditor}
                              listTagId='-1'
                              onClick={(e) => setArticle(e)}
                              id={fromTag.id}
                              name={fromTag.name}
                              listName={g.pName === 'Γενικές Ετικέτες' ? 'Ετικέτες' : g.pName ?? g.name}
                              refresh={() => loadData()}
                            />
                          ))}
                    </OrderList>
                  ))}
              </>
            )}
          </div>
        </div>
        <div className='rescom-main-panel'>
          {showHistory && !article && <History historyDate={historyDate} />}
          {article && (
            <div>
              
              <div className='rescom-article-title'>
              <div style={{fontSize:"9px", lineHeight:"2px", float:"right", opacity:".5"}}>{article.id}</div>
                <div>{article.title}</div></div>
            </div>
          )}
          {article && (
            <div className='rescom-article'>
              <Article key={article.id} appProps={props.appProps} id={article.id} hideTitle={true} padding='0px' />
            </div>
          )}
        </div>
      </div>
      <div className='rescom-bottom-bar'>
        <span style={{ fontSize: '13px', color: '#333' }}>resCom</span>&nbsp;©️ ΕΛΚΕ ΑΠΘ 1997-{new Date().getFullYear()}
        <div style={{ flexGrow: '1' }} />
        {data && data.resComInfo.isEditor && (
          <div
            className='rescom-bottom-bar-button'
            on
            onClick={() => {
              setArticle(null);
              setShowHistory(true);
            }}>
            Ιστορικό
          </div>
        )}
        {data && data.resComInfo.isEditor && (
          <div className='rescom-bottom-bar-icon'>
            <BsPlusSquare
              onClick={(e) => {
                e.stopPropagation();
                window.appProps.setModalData({
                  Title: props.tagTypeName ?? '',
                  hideFooter: true,
                  Component: (
                    <div style={{ display: 'flex', maxHeight: '50vh', minHeight: '50vh', overflow: 'hidden' }}>
                      <div className='modal-container'>
                        <Tags
                          modal={true}
                          tagType={props.tagType}
                          tagsType={data.tagsTypes}
                          appProps={window.appProps}
                          tagTypeName={props.tagTypeName}
                          onSelect={async (e) => {
                            var url = window.appProps.rootUrl + `&Parameters=Query:add_tag,id:${e},to:-1`;
                            await axios(url);
                            loadData();
                          }}
                        />
                      </div>
                    </div>
                  ),
                });
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ResCom;
