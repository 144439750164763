import React, { useEffect, useState } from 'react';
import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';
import { BsArrowDownSquare, BsArrowUpSquare, BsPlusSquare, BsDashSquare } from 'react-icons/bs';

import Tags from './Tags';
import axios from 'axios';

export function OrderList(props) {
  const [collapsed, setCollapsed] = useState(props.collapsed);

  useEffect(() => {
    if (props.collapsed !== collapsed) setCollapsed(props.collapsed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.collapsed]);

  return (
    <div className={`order-list ${props.leftBar ? 'left-bar' : ''}`}>
      <div
        className='order-list-header'
        onClick={() => {
          if (props.setExpandedId) {
            props.setExpandedId(props.collapsed ? props.tagType : null);
          } else {
            setCollapsed((prev) => !prev);
          }
        }}>
        <span style={{ flexGrow: '1' }}>{props.title}</span>
        {props.editable && (
          <BsPlusSquare
            onClick={(e) => {
              e.stopPropagation();
              window.appProps.setModalData({
                Title: props.tagTypeName ?? '',
                hideFooter: true,
                Component: (
                  <div style={{ display: 'flex', maxHeight: '50vh', minHeight: '50vh', overflow: 'hidden' }}>
                    <div className='modal-container'>
                      <Tags
                        modal={true}
                        tagType={props.tagType}
                        appProps={window.appProps}
                        tagTypeName={props.tagTypeName}
                        onSelect={async (e) => {
                          var url = window.appProps.rootUrl + `&Parameters=Query:add_tag,id:${e},to:${props.tagId}`;
                          let responce = await axios(url);
                          if (props.refresh) props.refresh(responce);
                        }}
                      />
                    </div>
                  </div>
                ),
              });
            }}
            className='order-list-header-icon'
          />
        )}
        {!collapsed && <BsArrowUpSquare className='order-list-header-icon' />}
        {collapsed && <BsArrowDownSquare className='order-list-header-icon' />}
      </div>
      {collapsed !== true && <>{props.children}</>}
    </div>
  );
}
export function OrderListItem(props) {
  let isFirst = props.list ? props.list[0].id === props.item.id : 0;
  let isLast = props.list ? props.list[props.list.length - 1].id === props.item.id : 0;

  return (
    <div className={`order-list-item ${props.small ? 'small ' : ''}`}>
      <div
        className='order-list-items-text'
        style={props.articles && {cursor:"pointer", color:"blue"}}
        onClick={() => {
          navigator.clipboard.writeText(props.name);
          console.log(`@@copy ${props.name}`);
          
          if (props.onClick && props.articles)
          {
            props.onClick(props.articles[0]);
          }
        }}>
          {props.name} {props.showIds && <> - {props.id}</>}
      </div>

      <div className='order-list-items-icons'>
        {props.removable && props.editable && (
          <BsDashSquare
            onClick={() => {
              window.appProps.setModalData({
                Title: 'Επιβεβαίωση Αφαίρεσης Ετικέτας',
                SubTitle: `Να πραγματοποιηθεί η αφαίρηση της ετικέτας ${props.name} από την κατηγορία ${props.listName};`,
                isQuestion: true,
                handleConfirm: async () => {
                  var url = window.appProps.rootUrl + `&Parameters=Query:remove_tag,id:${props.id},from:${props.listTagId}`;
                  let responce = await axios(url);
                  if (props.refresh) props.refresh(responce);
                },
              });
            }}
            className={`order-list-item-btn`}
          />
        )}
      </div>
      {props.list && (
        <>
          <FaChevronCircleUp
            className={`order-list-item-btn${isFirst ? ' disabled' : ''}`}
            onClick={() => {
              if (!isFirst) {
                if (props.onOrderClick) props.onOrderClick(true);
              }
            }}
          />
          <div style={{ opacity: '.3', paddingTop: '5px', fontSize: '10px' }}>{props.item?.sort_order}</div>
          <FaChevronCircleDown
            className={`order-list-item-btn${isLast ? ' disabled' : ''}`}
            onClick={() => {
              if (!isLast) {
                if (props.onOrderClick) props.onOrderClick(false);
              }
            }}
          />
        </>
      )}
    </div>
  );
}
